import React from "react"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Img from "gatsby-image"
import BackLink from "../components/utils/backlink"
import Gallery from "../components/utils/gallery"

const StoryTemplate = ({ pageContext }) => {
    const { data } = pageContext
    const { featured_media, acf } = data
    
    return (
        <Layout mainClassName="pb-0">
            {data &&
            <>
            
            <SEO title={ data.title } description={ data.excerpt } />
            <article>
                <div className="container relative px-0 -mb-3">
                    {featured_media && featured_media.localFile &&
                        <figure className="relative gradient">
                            <Img fluid={featured_media.localFile.childImageSharp.fluid}
                                alt={featured_media.alt_text}
                                title={featured_media.title} />
                        </figure>
                    }
                    
                    <header className={["p-md", featured_media ? "absolute inset-0 text-white flex flex-col justify-end items-start" : ""].join(" ")}>
                        <div className="flex-1">
                            <span className="bg-primary inline-block py-1 px-2 tiny">Story</span>
                        </div>
                    
                        <BackLink to="/" />
                        <h1 className="mt-2 text-parent" dangerouslySetInnerHTML={{ __html: data.title }} />
                        {data.excerpt &&
                            <div className="text-lg font-bold" dangerouslySetInnerHTML={{ __html: data.excerpt }} />
                        }
                    </header>
                </div>
                
                <div className="container p-md pb-24 bg-white rounded-t-xl relative z-10 text-base min-h-screen">
                    <div className="article" dangerouslySetInnerHTML={{ __html: data.content }} />
                    {acf.pictures &&
                        <div className="-mx-sm">
                            <Gallery pictures={acf.pictures} />
                        </div>
                    }
                </div>
            </article>
            
            </>
            }
        </Layout>
    )
}

export default StoryTemplate
