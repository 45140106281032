import React from "react"
import Img from "gatsby-image"
import Glide from "@glidejs/glide"
import "@glidejs/glide/dist/css/glide.core.min.css"

class Gallery extends React.Component {

    componentDidMount() {
        new Glide('.glide', {
            bound: true,
            gap: -16,
            peek: { before: 0, after: 24 },
            perView: 1
        }).mount()
    }

    render() {
        const { pictures, container } = this.props

        let trackClassNames = "glide__track"

        if (container)
            trackClassNames += " overflow-visible"

        return (
            <div className={container ? "overflow-hidden" : ""}>
                <div className={container ? "container" : ""}>
                    <div className="glide">
                        <div data-glide-el="track" className={trackClassNames}>
                            <ul className="glide__slides">
                                {pictures.map((picture, i) => {
                                    if (picture && picture.localFile) {
                                        return (
                                            <li key={i} className="glide__slide p-sm pb-md leading-none">
                                                <Img
                                                    fluid={picture.localFile.childImageSharp.fluid}
                                                    alt={picture.alt_text}
                                                    title={picture.title}
                                                    className="shadow-lg overflow-hidden rounded-sm"
                                                    />
                                            </li>
                                        )
                                    }

                                    return <></>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Gallery
